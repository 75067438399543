#contactContain {
  background: linear-gradient(
    to top,
    rgba(0,0,0, 0.9),
    rgba(0,0,0, 0.0),
    rgba(0,0,0, 0.9)
  ),url(../../images/costa-rica-trek.jpeg);
  /* background: url(../../images/costa-rica-trek.jpeg); */
  width: 100%;
  height: 92vh;
  background-size: cover;
  position: relative;
}

#contactBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  min-height: 60vh;
  background-color: rgba(66, 66, 66, 0.8);
  backdrop-filter: blur(2px);
  border-radius: 10px;
}
#contactH1 {
  text-align: center;
  text-decoration: none;
  margin-bottom: 0%;
  color: white;
}
hr {
  width: 50%;
  border-color: var(--Tan);
}

/* a:hover {
  color: var(--Tan);
}
svg:hover{
    color: var(--Tan);

} */
#contactH2 {
  text-align: center;
  color: white;
  margin-bottom: 0;
}
.linkContain {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3%;
}
#contactPara {
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}
