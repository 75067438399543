
#logoContain{
    grid-column: 1/6;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: start;
}

#navLinksContain{
    grid-column: 6/13;
    display: flex;
    height: 100%;
   
    justify-content: flex-end;
    align-items: center;
    

}
.navLinks{
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin-left:20px;
    margin-right:20px ;
    

}
.MuiAppBar-root{
   
   
        background-color: var(--Oxblood) !important;
    
}

.icon{
    color: white;
}
.link{
    color: white;
    text-decoration: none;
    
}
.MuiAppBar-positionFixed{
    position: inherit !important;
}


/* new */

.MuiToolbar-root{
    display: grid !important;
    grid-template-columns: repeat(12, 1fr);
}
#logoText{
    font-family: 'MonteCarlo', cursive;
    
}
.MuiDrawer-paper{
    background-color: var(--Oxblood) !important;
    backdrop-filter: blur(3px);
}

@media only screen and (max-width: 950px){
    #logoContain{
        grid-column: 1/8;
     
    
    }
    #navLinksContain{
        grid-column: 8/13;
        
        
    
    }
}