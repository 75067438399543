
#whoWeAreContain{
    background-color: var(--Grain);
}

#famPic{
    content:url("../../images/Family.jpeg");
    height: 300px;
    /* background: url("../../images/Family.jpeg"); */
    margin: 0 auto;
}
#whoWeAreHeader{
    width: 100%;
    background: linear-gradient(
        to top,
        rgba(0,0,0, 0.8),
        rgba(0,0,0, 0.0),
        rgba(0,0,0, 0.8)
      ),url(../../images/plant.jpg);
    background-repeat: none;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px
}
#introText{
    width: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    color: rgb(228, 226, 226);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    
}
.blockContain{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.block{
    text-align: center;
    width: 80%;
   
}
#mapContain{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
#mapContain img{
  width: 45%;
  border-radius: 10px
}

@media only screen and (max-width: 520px){
    #famPic{
        height: 190px;
    }
    #introText{
        width: 100%;
    }
    #mapContain img{
        width: 100%;
    }
}