#topSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;

}
.topSectionBlock {
  width: 50%;
}
.wwButton {
  background-color: var(--Grain);
  border-width: 3px;
  border-style: solid;
  border-color: var(--Oxblood);
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin: 10px;
}
h1 {
  text-decoration: underline;
  color: var(--Chalkboard);
}

.centerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.firstText {
  padding-left: 20px;
  padding-right: 20px;
}
/*  */

.ministriesRow {
  grid-column: 2/8;
  width: 100%;
}

#mainContain{
  
    background-color: var(--Grain);
    height: 100%;
  
}
@media only screen and (max-width: 700px) {
  .topSectionBlock {
    width: 100%;
  }
}
