:root{
  --Grain: #d7cec7;
  --Chalkboard: #565656;
  --Oxblood: #76323f;
  --Tan: #C09F80;
}
.MuiContainer-root{
  padding: 0px !important
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  
 
}
a:hover{
  color: var(--Tan);
}
p{
  font-size: 18px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
