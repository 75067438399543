:root{
  --Grain: #d7cec7;
  --Chalkboard: #565656;
  --Oxblood: #76323f;
  --Tan: #C09F80;
}
.MuiContainer-root{
  padding: 0px !important
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  
 
}
a:hover{
  color: #C09F80;
  color: var(--Tan);
}
p{
  font-size: 18px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


#logoContain{
    grid-column: 1/6;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: start;
}

#navLinksContain{
    grid-column: 6/13;
    display: flex;
    height: 100%;
   
    justify-content: flex-end;
    align-items: center;
    

}
.navLinks{
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin-left:20px;
    margin-right:20px ;
    

}
.MuiAppBar-root{
   
   
        background-color: var(--Oxblood) !important;
    
}

.icon{
    color: white;
}
.link{
    color: white;
    text-decoration: none;
    
}
.MuiAppBar-positionFixed{
    position: inherit !important;
}


/* new */

.MuiToolbar-root{
    display: grid !important;
    grid-template-columns: repeat(12, 1fr);
}
#logoText{
    font-family: 'MonteCarlo', cursive;
    
}
.MuiDrawer-paper{
    background-color: var(--Oxblood) !important;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

@media only screen and (max-width: 950px){
    #logoContain{
        grid-column: 1/8;
     
    
    }
    #navLinksContain{
        grid-column: 8/13;
        
        
    
    }
}
.carousel-root{
    z-index: 1;

   top: 1px;
   
    
}
.carousel-screens{
    min-height: 91vh;
    
}
/* First Slide */
#firstSlide{
    background: linear-gradient(
        to top,
        
        rgba(0,0,0, 0.5),
        rgba(0,0,0, 0.3),
        rgba(0,0,0, 0.5)
      ),url(/static/media/farmland.29771475.jpg);
    /* background: url(../images/plant.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
#costaRicaHeader{
    display: flex;
    justify-content: center;
   
    width: 100%;
   
    padding-top: 1vw;
   
}

#costaRicaHeader h1{
    color: rgb(255, 255, 255);
    text-decoration: none;
    
}
.firstSlideButton{
    background: none;
    width: 80px;
    height: 40px;
    border: 2px solid white;
    color: white;

}
.firstSlideSMButton{
    display: none;
    background: none;
    width: 80px;
    height: 40px;
    border: 2px solid white;
    color: white;
    

}
#firstSlideContentContain{
    min-width: 80%;
    min-height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  
  /* background-color: rgba(26, 26, 26, 0.8); */
  color: white;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around ;
}
#firstSlideContentContain h1{
    
  color: white;
  text-decoration: none;
}
#firstSlideText{
    width: 70%;
    background-color: rgba(26, 26, 26, 0.8);
    border-radius: 10px;
    padding: 80px 40px 80px 40px;
    
}
@media only screen and (max-width: 1271px){
    #firstSlideButtonContain{
        display: none;
    }
    #firstSlideSMButton{
        display: block;
    }
}
@media only screen and (max-width: 565px){
    #firstSlideContentContain{
        width: 100%;
    }
    #firstSlideText{
        width: 90%;
        padding: 0px
    }
    
}

/* Second Slide */
#secondSlide{
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
}

#secondBack{
    width: 33.3%;
    height: 100%;
    background: linear-gradient(
        to top,
        rgba(0,0,0, 0.9),
        rgba(0,0,0, 0.0),
        rgba(0,0,0, 0.9)
      ),url(/static/media/costa-rica-carry.5d985f71.jpeg);
    /* background: url(../images/plant.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
}
#firstBack{
    width: 33.3%;
    height: 100%;
    background: linear-gradient(
        to top,
        rgba(0,0,0, 0.9),
        rgba(0,0,0, 0.5),
        rgba(0,0,0, 0.9)
      ),url(/static/media/costa-rica-worship.0d4b5b8f.jpeg);
    /* background: url(../images/plant.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
}
#thirdBack{
    width: 33.3%;
    height: 100%;
    background: linear-gradient(
        to top,
        rgba(0,0,0, 0.9),
        rgba(0,0,0, 0.0),
        rgba(0,0,0, 0.9)
      ),url(/static/media/costa-rica-serve.050f28ce.jpeg);
    /* background: url(../images/plant.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
}
#topSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;

}
.topSectionBlock {
  width: 50%;
}
.wwButton {
  background-color: var(--Grain);
  border-width: 3px;
  border-style: solid;
  border-color: var(--Oxblood);
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin: 10px;
}
h1 {
  text-decoration: underline;
  color: var(--Chalkboard);
}

.centerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.firstText {
  padding-left: 20px;
  padding-right: 20px;
}
/*  */

.ministriesRow {
  grid-column: 2/8;
  width: 100%;
}

#mainContain{
  
    background-color: var(--Grain);
    height: 100%;
  
}
@media only screen and (max-width: 700px) {
  .topSectionBlock {
    width: 100%;
  }
}

#contactContain {
  background: linear-gradient(
    to top,
    rgba(0,0,0, 0.9),
    rgba(0,0,0, 0.0),
    rgba(0,0,0, 0.9)
  ),url(/static/media/costa-rica-trek.0cd6d862.jpeg);
  /* background: url(../../images/costa-rica-trek.jpeg); */
  width: 100%;
  height: 92vh;
  background-size: cover;
  position: relative;
}

#contactBox {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  min-height: 60vh;
  background-color: rgba(66, 66, 66, 0.8);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-radius: 10px;
}
#contactH1 {
  text-align: center;
  text-decoration: none;
  margin-bottom: 0%;
  color: white;
}
hr {
  width: 50%;
  border-color: var(--Tan);
}

/* a:hover {
  color: var(--Tan);
}
svg:hover{
    color: var(--Tan);

} */
#contactH2 {
  text-align: center;
  color: white;
  margin-bottom: 0;
}
.linkContain {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3%;
}
#contactPara {
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}


#whoWeAreContain{
    background-color: var(--Grain);
}

#famPic{
    content:url(/static/media/Family.e7937f07.jpeg);
    height: 300px;
    /* background: url("../../images/Family.jpeg"); */
    margin: 0 auto;
}
#whoWeAreHeader{
    width: 100%;
    background: linear-gradient(
        to top,
        rgba(0,0,0, 0.8),
        rgba(0,0,0, 0.0),
        rgba(0,0,0, 0.8)
      ),url(/static/media/plant.f909166f.jpg);
    background-repeat: none;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px
}
#introText{
    width: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    color: rgb(228, 226, 226);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 20px;
    
}
.blockContain{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.block{
    text-align: center;
    width: 80%;
   
}
#mapContain{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
#mapContain img{
  width: 45%;
  border-radius: 10px
}

@media only screen and (max-width: 520px){
    #famPic{
        height: 190px;
    }
    #introText{
        width: 100%;
    }
    #mapContain img{
        width: 100%;
    }
}
.MuiCard-root{
    width: 300px;
    margin: 10px;
}
#cardFlexContain{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#getInvolvedContain{
    background-color: var(--Grain);
  }
@media only screen and (max-width: 648px){
    .MuiCard-root{
        width: 100%;
    }
}
